
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'map assessor component',
  components: {
    Form,
    Field,
  },
  data() {
    return {
      assessorSelected: [] as any,
      assesor_ids: [],
      select_assesor_ids: [],
      loader: false,
      assessorInfos: [] as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showCourseList: false,
      showMapCourseList: false,
      showtrainingProviderNotice: false,
      tranche_id: '',
      entity_id: '',
      institute_id: '',
      entityInfos: [] as any,
      showSubmit: false,
      load: false,
      tranches: [] as any,
      institutes: [] as any,
      tempCourseInfos: [] as any,
    };
  },
  async created() {
    await this.getEntityInfos();
    await this.getTranche();
  },
  methods: {
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async formSubmit() {
      let formData = new FormData();
      this.loading = true;
      let user_id = VueCookieNext.getCookie('_seip_user');
      formData.set('user_id', user_id.id);
      formData.set('tranche_id', this.tranche_id);

      formData.set('entity_id', this.entity_id);
      formData.set('institute_id', this.institute_id);
      formData.set('map_assesor_ids', JSON.stringify(this.assessorSelected));
      // formData.set('unmap_assesor_ids', JSON.stringify(this.assessorInfos));

      await ApiService.post(
        'institute/mapping/map_assessor_institute_save',
        formData
      )
        .then((response) => {
          if (response.data.status == 'success') {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              // this.tranche_id = '';
              // this.entity_id = '';
              this.institute_id = '';
              (this.assessorInfos = [] as any),
                (this.assessorSelected = [] as any);
              this.loading = false;
              this.showSubmit = false;
            });
          }
          if (response.data.status == 'error') {
            Swal.fire({
              title: 'Error!',
              text: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            }).then(() => {
              // this.tranche_id = '';
              // this.entity_id = '';
              this.institute_id = '';
              (this.assessorInfos = [] as any),
                (this.assessorSelected = [] as any);
              this.loading = false;
              this.showSubmit = false;
            });
          }
        })

        .catch(({ response }) => {
          this.loading = false;

          console.log(response);
        });
    },
    async getEntityInfos() {
      this.load = true;
      let entity_id = this.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    selectedAssessors() {
      this.assesor_ids.forEach((item) => {
        // if not find any id (basically never find it )on right side then push else skip
        const alreadySelected = this.assessorSelected.some(
          (s_item) => s_item['id'] === item['id']
        );

        if (!alreadySelected) {
          this.assessorSelected.push(item);
          this.assessorInfos = this.assessorInfos.filter(
            (info) => info['id'] !== item['id']
          );
        }
      });
      // console.log(this.assessorInfos);
      // console.log(this.assessorSelected);
    },
    unselectedAssessors() {
      this.select_assesor_ids.forEach((item) => {
        const alreadyUnselected = this.assessorInfos.some(
          (s_item) => s_item['id'] === item['id']
        );

        if (!alreadyUnselected) {
          this.assessorInfos.push(item);
          this.assessorSelected = this.assessorSelected.filter(
            (selected) => selected['id'] !== item['id']
          );
        }
      });
    },

    selectAllAssesor() {
      Array.prototype.push.apply(this.assessorSelected, this.assessorInfos);
      this.assessorInfos.splice(0);
    },
    unselectAllAssesor() {
      Array.prototype.push.apply(this.assessorInfos, this.assessorSelected);
      this.assessorSelected.splice(0);
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    async assessorList() {
      this.loader = true;
      await ApiService.get(
        'institute/mapping/map_institute_assessor?entity_id=' +
          this.entity_id +
          '&institute_id=' +
          this.institute_id +
          '&tranche_id=' +
          this.tranche_id
      )
        .then((response) => {
          this.assessorInfos = response.data.data.assessor_info;
          this.assessorSelected = response.data.data.map_info;
          // console.log(this.assessorSelected);
          if (this.assessorSelected != '' || this.assessorInfos != '') {
            this.showSubmit = true;
          }
          // this.assessorSelected = this.mapInfo;
          this.loader = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async assessorListFirst() {
      this.institute_id = '';

      this.getInstituteInfo();
      this.loader = true;
      await ApiService.get(
        'institute/mapping/map_institute_assessor?entity_id=' +
          this.entity_id +
          '&institute_id=' +
          this.institute_id +
          '&tranche_id=' +
          this.tranche_id
      )
        .then((response) => {
          this.assessorInfos = response.data.data.assessor_info;
          this.assessorSelected = response.data.data.map_info;
          // console.log(this.assessorSelected);
          if (this.assessorSelected != '') {
            this.showSubmit = true;
          }
          // this.assessorSelected = this.mapInfo;
          this.loader = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInstituteInfo() {
      this.load = true;
      let entity_id = this.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('institute/list?entity_id=' + entity_id)
        .then((response) => {
          this.institutes = [] as any;
          this.institutes = response.data.data;

          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseMapList() {
      this.showMapCourseList = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
